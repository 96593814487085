<template lang="pug">
.kamikaze
  .kamikazeContentContainer.columns
    .kamikazeImageContainer.column
      img(src="../assets/kamikaze_l.svg" width="230" height="320")
    .kamikazeStoryContainer.column
      h1.kamikazeTitle He’s a ninja kid, call him “KAMIKAZE”
      table.table
        tr
          th Name
          td KAMIKAZE
        tr
          th Age 
          td 4 
        tr
          th Birthplace 
          td Iga, Japan 
        tr
          th Skill 
          td Forget 
        tr
          th Family 
          td Father, Mother, Older Sister, Dog 
        tr
          th Job 
          td Ninja Kid 
        tr
          th Signature word
          td GOZARU
</template>

<script>
export default {
  name: 'kamikaze',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="stylus">
.kamikaze
  width: 80%
  margin-left: auto 
  margin-right: auto
  margin-top: 80px
  margin-bottom: 140px
  .kamikazeContentContainer
    .kamikazeImageContainer
      text-align: center
    .kamikazeStoryContainer
      .kamikazeTitle
        font-size: 1.1rem
        font-weight: bolder
        line-height: 3em
      .table
        width: 100%

</style>
